body {
  font-family: 'vista-sans';
  font-weight: 700;
  font-size: 16px;
  color: black;
}

* {
  box-sizing: border-box;
  hyphens: auto;
  margin: 0;
  padding: 0;
}

@keyframes border_anim {
  0%{
      width: 0%;
    }
  100%{
      width: 100%;
    }
}

/** General Layout **/
.content-container {
  padding: 0 40px;
}

h1 {
  font-family: 'vista-sans';
  font-weight: 700;
  font-size: 105px;
  font-size: clamp(2rem, 105px, 10vh);
  line-height: 1.1;
}

h2 {
  font-family: 'vista-sans';
  font-weight: 700;
  font-size: 65px;
  line-height: 87px;
}

li {
  font-family: 'vista-sans';
  font-weight: 400;
  font-size: 17px;
}

p {
  font-family: 'vista-slab';
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  opacity: 0.9;
  word-wrap: break-word;
}

/** strapi rich text markdown for ReactMarkdown component - same as p **/
.strapi-markdown p:not(:last-child) {
  font-family: 'vista-slab';
  font-weight: 400;
  margin-bottom: 40px;
  opacity: 0.9;
  font-size: 18px;
  line-height: 34px;
}


button {
  font-family: 'vista-sans';
  font-weight: 700;
  font-size: 16px;
  border-width: 0;
  background-color: transparent;
}

.overline-text {
  font-family: 'vista-sans';
  font-weight: 400;
  font-size: 14px;
}

.teaser-text {
  font-family: 'vista-sans';
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.teaser-text > p {
  font-family: 'vista-sans';
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.menu-info-box-text > p {
  color: white;
  font-family: 'vista-sans';
  font-size: 65px;
  font-weight: 700;
  line-height: 1.01;
  text-align: left;
}

.menu-info-box-text > ul {
  padding: 0;
  padding-left: 15px;
  margin-left: 5px;
  color: white;
}

.menu-info-box-text > ul li {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
}

.footer-left p span {
  font-family: 'vista-sans';
}


/** Menu Overlay **/
.offcanvas {
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.menu-navbar {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 125px;
  width: 100%;
  opacity: 1;
  margin: 0 auto -125px auto;
}

.menu-header {
  display: flex;
  z-index: 1050;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.menu-header.mobile {
  display: none;
}

.menu-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 160px !important;
  padding-bottom: 40px !important;
  overflow-y: auto;
}

.menu-body a {
  width: fit-content;
  color: white;
  text-decoration: none;
}

.menu-body a .nav-item {
  position: relative;
}

.menu-body a .nav-item:hover:before{
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  z-index: 100;
  border-bottom: solid 8px #F96532;
  animation: border_anim 0.6s linear forwards;
}

.menu-body a:nth-of-type(3n) .nav-item:hover:before{
  animation: border_anim 1s linear forwards;
}
.menu-body a:nth-of-type(4n) .nav-item:hover:before{
  animation: border_anim 1s linear forwards;
}

.menu-body .row  {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
}

.menu-body .row .column.left {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.menu-body .row .column.right {
  padding: 0;
  display: flex;
  width: 50%;
  max-width: 750px;
}


.menu-body .info-card {
  display: flex;
  flex-direction: column;
  background-color: #F96532;
  border-radius: 15px;
  height: 60vh;
  width: 750px;
  margin-top: auto;
  padding: 5rem 5rem 5.69rem;
}

.menu-body .info-card > div:last-of-type{
  margin-top: auto;
  margin-bottom: 10px;
}

.menu-body .info-card .overline-text {
  font-size: 16px;
  line-height: 30px;
}

.menu-body .info-card section:last-of-type{
  margin-top: auto;
  margin-left: auto;
}

.menu-footer {
  margin-top: auto;
  color: rgb(211,211,211, 0.5);
}

.menu-footer a {
  margin-right: 25px;
  color: rgb(211,211,211, 0.5);
  position: relative;
}

.menu-footer a:hover:before{
  content: '';
  position: absolute;
  bottom: -3px;
  width: 0;
  z-index: 10;
  border-bottom: solid 3px #F96532;
  animation: border_anim 0.5s linear forwards;
}

.menu-phone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: none;
  border-radius: 25px;
  height: 50px;
  -webkit-appearance: none;
  text-decoration: none;
  transition: color 0.5s, background-color 0.5s;
}

.menu-phone.orange {
  color: white;
  background-color: #F96532;
}

.menu-phone.orange:hover {
  background-color: #F67143;
}

.menu-phone.white {
  color: white;
  background-color: rgb(255,255,255, 0.3);
}

.menu-phone.white:hover {
  background-color: rgb(255,255,255, 0.5);
}

.menu-phone.black {
  color: black;
  background-color: white;
}

.menu-phone.black:hover {
  background-color: #FAFAFA;
}

.menu-phone .btn-text {
  padding: 0 28px;
}

.menu-phone .hover-show {
  display: none;
}

.menu-phone:hover .hover-show {
  display: inline-block;
}

.menu-body .hide-desktop{
  display: none;
}

@media screen and (min-width: 991.98px) and (max-width: 1200px) {
  h2 {
    font-size: 46px;
    line-height: 64px;
  }
  .menu-info-box-text > p {
    font-size: 46px;
  }
}

@media screen and (max-width: 991.98px) {
  h1 {
    font-size: 53px;
    line-height: 63px;
  }

  h2 {
    font-size: 34px;
    line-height: 48px;
  }

  .content-container {
    padding: 0 30px;
  }

  .menu-header.desktop {
    display: none;
  }
  .menu-header.mobile {
    display: flex;
  }
  .menu-body {
    margin-top: 50px !important;
    padding-top: 50px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .menu-body .row {
    flex-direction: column;
    width: 100%;
    gap: 50px;
  }
  .menu-body .row .column.left {
    width: 100%;
  }
  .menu-body .row .column.right {
    width: 100%;
  }
  .menu-body .row .column .info-card {
    width: 100%;
    padding: 25px 20px;
    height: unset;
  }
  .menu-body .row .column .info-card > *:not(:last-child):not(:first-child) {
  }
  .menu-body .row .column .info-card > *:last-child {
    margin-bottom: 0;
    margin-top: 14px;
  }
  .menu-body .row .column .info-card >div:first-child .pill.white  {
     display: none;
     margin: 0;
  }
  .menu-body .row .column .info-card .menu-info-box-text p {
    font-size: 29px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
  }
  .menu-body .row .column .info-card .menu-info-box-text ul li {
    font-size: 1rem;
    line-height: 160%;
    margin-bottom: 0.6rem;
    font-weight: 700;
    text-align: left;
  }
  .menu-body .column.right {
    display: none;
  }
  .menu-footer .hide-mobile{
    display: none;
  }

  .menu-phone:hover .hover-show {
    display: none;
  }

  .menu-body  .menu-footer {
    padding-right: 12px;
    padding-left: 12px;
  }
  .menu-body  .menu-body-phone {
    padding-right: 12px;
    padding-left: 12px;
  }

}

@media screen and (max-width: 768px) {
  .menu-body .hide-desktop{
    display: block;
    margin-top: 3rem;
  }
  .menu-body-phone {
    margin-top: auto;
  }
  .menu-footer {
    margin-top: 25px;
  }
  .menu-body .row .column .info-card .menu-info-box-text p{
    font-size: 1.5rem;
  }
  .menu-body .row .column .info-card .menu-info-box-text ul li{
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1800px) {

  h1 {
    hyphens: none;
  }

  .content-container {
    width: 100%;
    max-width: 1840px;
    margin: 0 auto;
  }
}

::-webkit-scrollbar {
  display: none;
}
.modal-backdrop.show{
  z-index: 9998;
}
.modal.show{
  z-index: 9999;
}
.modal.show .modal-dialog{
  z-index: 9999;
  max-height: 80vh;
}
@media screen and (max-width: 768px) {
  .modal.show .modal-dialog{
    max-width: calc(100% - 3.5rem);
  }
}
@media screen and (max-width: 576px) {
  .modal.show .modal-dialog{
    max-width: 100%;
  }
  h1 {
    font-size: 40px;
    line-height: 1.3;
  }
}


